import React, { useState } from "react";
import styles from "./Types.module.css";
import styles2 from "./Searching/Searching.module.css";
import BarcodeScannerComponent from "react-webcam-barcode-scanner";
import axios from "axios"; //used for api routes
import { Snackbar } from "@mui/material";

//editing the image.js
//problem description: does not reset the data after the scan

import MuiAlert from "@mui/material/Alert";
import List from "../../List/List.js";

async function getProducts(input, type) {
  return axios
    .get(`/api/product/upc/${input}`)
    .then((res) => {
      console.log(res);
      return res.data.status;
    })
    .catch((err) => {});
}

async function submitSuppRequest(barcode, username) {
  console.log(username);
  console.log(barcode);
  return axios
    .post(`/api/product/insert/request`, {
      username: username,
      upc: barcode,
    })
    .then((res) => {
      console.log(res);
      return res.data.status;
    })
    .catch((err) => {});
}

async function addProduct(id, username, value) {
  console.log("HEY");
  let body = { dsld_id: id };
  let route = `/api/user/${username}/inventory`;

  // add product to inventory
  return axios
    .post(route, body)
    .then((res) => {
      let check = true;
      if (res.status === 200) {
        console.log("Product added to inventory.");
        if (value === 1) {
          const body = {
            dsld_id: id,
            username: username,
            amount: 0, //By default the amounts for all stacks will be zero, so we don't even bother passing it in
          };
          axios.post("/api/event", body).then((res) => {
            if (res.status === 200) {
              return 200;
            } else {
              check = false;
            }
          });
        }
      }
      return 200;
    })
    .catch((err) => {
      if (err.response && err.response.status === 409) {
        // Duplicate product case
        return 409;
      } else {
        console.log("[Error Add Product]: ", err);
        return 500; // Other error
      }
    });
}

// Trigger Freshdesk Help Widget when the scan fails
const showHelpWidget = (barcode) => {
  const subject = `Barcode Scan Faliure: ${barcode}`;
  window.FreshworksWidget("open");
  window.FreshworksWidget("prefill", "ticketForm", {
    subject: subject,
    description:
      "Product Name:\nServing Size:\nServings Per Container:\nSupplament Type (Capsules, Tablets, Liquid, Etc.):",
    priority: 4,
    type: "Recommend New Product",
  });
};

function Image({ username, value }) {
  const [data, setData] = useState(0); //actual upc code
  const [show, setShow] = useState(true); //camera
  const [product_name, setProductName] = useState(""); //product name
  const [dsldid, setDsldId] = useState(null); //dsld_id
  const [barcodeScanned, setBarcodeScanned] = useState(false);
  var barcode = ""; //upc code without leading zero

  //Snackbar setups
  const [successOpen, setSuccessOpen] = useState(false);
  const [alreadyAddedOpen, setAlreadyAddedOpen] = useState(false);
  const [errorAddingOpen, setErrorAddingOpen] = useState(false);
  const handleSuccessClose = () => {
    setSuccessOpen(false);
    return;
  };
  const handleAlreadyAddedClose = () => {
    setAlreadyAddedOpen(false);
    return;
  };
  const handleErrorAddingClose = () => {
    setErrorAddingOpen(false);
    return;
  };
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const showResultMessage = (result) => {
    console.log(result);
    if (result === 409) {
      setAlreadyAddedOpen(true);
    } else if (result === 200) {
      setSuccessOpen(true);
    } else {
      setErrorAddingOpen(true);
    }
  };

  const onUpdateScreen = (err, result) => {
    if (result) {
      console.log(result);
      setBarcodeScanned(true);
      setData(result.text);
      setShow(false);
      barcode = "" + result.text;
      barcode = barcode.slice(1);

      getProducts(barcode, "upc")
        .then((barcodeResult) => {
          if (barcodeResult === undefined) {
            undefinedItem();
            console.log(barcode);
            showHelpWidget(barcode);
            submitSuppRequest(barcode, username);
          } else {
            console.log(barcodeResult);
            setProductName(barcodeResult.product_name);
            setDsldId(barcodeResult.dsld_id);
          }
        })
        .catch((error) => {
          console.error("Error fetching product:", error);
          showHelpWidget(barcode); // Open the widget on error
        });
    }
  };
  const undefinedItem = () => {
    setProductName("");
  };
  const setBoth = () => {
    setShow(true);
    setBarcodeScanned(false);
  };

  const displayProducts = () => {
    const image =
      "https://s3-us-gov-west-1.amazonaws.com/cg-355fa78b-864d-4a44-b287-0369f11f024a/pdf/" +
      dsldid +
      ".pdf";

    if (product_name != "") {
      return (
        <div className={styles2.selected}>
          <div>{`Name - ${product_name}`}</div>
          <div>{`UPC - ${data}`}</div>
          <button
            className={styles.add}
            onClick={() =>
              addProduct(dsldid, username, 0).then((result) => {
                showResultMessage(result);
              })
            }
          >
            Add Product to Inventory
          </button>
          <button
            className={styles.add}
            onClick={() =>
              addProduct(dsldid, username, value).then((result) =>
                showResultMessage(result)
              )
            }
          >
            Add Product to Inventory and into "My Supplements"
          </button>
          <div class={styles.scanAgain}>
            <button
              variant="contained"
              color="red"
              size="small"
              onClick={() => setBoth()}
            >
              {" "}
              Scan again{" "}
            </button>
            <iframe src={image} />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <p>No Products Found</p>
          <p>
            Click the Help Desk icon on the left panel to send a request to add
            your product to NutraData™
          </p>
          <button
            variant="contained"
            color="red"
            size="small"
            onClick={() => setBoth()}
          >
            {" "}
            Scan again{" "}
          </button>
        </div>
      );
    }
  };

  /*const disablingBTN = () => {
    if (!show) {
      return (
        <div>
          <button variant="contained" color="red" size="small" onClick={() => setShow(true)}> Capture </button>
        </div>
      );
    }
    else {
      return (
        <div class={styles.scanningDirections}>
          Bring product's barcode close enough to fill screen. Camera must come close to clearly scan barcode.
        </div>
      )
    }
  };*/

  return (
    <div class={styles.cs}>
      <h1>Barcode Scanner</h1>
      {!barcodeScanned && product_name == "" && !show ? (
        <p>Press the capture button to start scanning</p>
      ) : null}
      <>
        {show && (
          <div class={styles.camera}>
            <BarcodeScannerComponent
              width={500}
              height={500}
              onUpdate={(err, result) => onUpdateScreen(err, result)}
            />
          </div>
        )}
        {!barcodeScanned ? null : displayProducts()}
      </>
      <Snackbar
        anchorOrigin={{
          vertical: "middle",
          horizontal: "center",
        }}
        style={{
          width: "250px",
          textAlign: "center",
        }}
        open={alreadyAddedOpen}
        autoHideDuration={3000}
      >
        <Alert
          onClose={handleAlreadyAddedClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          Product has already been added to your inventory
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "middle",
          horizontal: "center",
        }}
        style={{
          width: "250px",
          textAlign: "center",
        }}
        open={errorAddingOpen}
        autoHideDuration={3000}
      >
        <Alert
          onClose={handleErrorAddingClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          Error adding to inventory
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "middle",
          horizontal: "center",
        }}
        style={{
          width: "250px",
          textAlign: "center",
        }}
        open={successOpen}
        autoHideDuration={3000}
      >
        <Alert
          onClose={handleSuccessClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Product successfully Added!
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Image;