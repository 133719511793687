import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import styles from "./Home.module.css";
import "material-design-icons";
import List from "./Pages/List/List";
import Totals from "./Pages/Totals/Totals";
import Visualize from "./Pages/Visualize/Visualize";
import Shop from "./Pages/Shop/Shop";
import Account from "./Pages/Account/Account";
import Add from "./Pages/Add/Add";
import Help from "./Pages/Help/Help";
import BetaTesters from "./Pages/BetaTesters/BetaTesters";
import Admin from "./Pages/Admin/Admin";
import axios from "axios";
import { Button } from "@material-ui/core";
import Message from "./Message";
import Interactions from "./Pages/Interactions/Interactions.js";

export default function Home(props) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const [menuOpen, setMenuOpen] = useState(false); // State to manage mobile menu visibility

  const toggleMenu = () => {
    setMenuOpen(!menuOpen); // Toggle the mobile menu
  };

  const [logout, setLogout] = useState(false);
  const [selected, setSelected] = useState("Home");
  const [accountMessage, setAccountMessage] = useState("uninitialized");
  const logoutAcc = () => {
    axios
      .get("/auth/logout")
      .then((response) => true)
      .then((data) => {
        setLogout(data);
      });
  };

  const propState = props.location.state;
  let username;
  let email;
  let user_status;
  if (propState) {
    username = propState.username;
    email = propState.email;
    user_status = propState.user_status;
    if (accountMessage === "uninitialized") {
      setAccountMessage(propState.account_message);
    }
    // document.cookie = `username=${username}; path=/home`
  } else {
    return <Redirect to="/" />;
  }

  const handleButtonClick = () => {
    setAccountMessage("User Acknowledged");
    console.log("button clicked");
  };

  const types = {
    Home: {
      icon: "featured_play_list",
      component: <List username={username} />,
    },
    Inventory: {
      icon: "description",
      component: <Totals username={username} />,
    },
    // Add: {
    // 	icon: 'add_circle',
    // 	component: <Add username={username} />
    // },
    Visualize: {
      icon: "bar_chart",
      component: <Visualize username={username} />,
    },
    // Shop: {
    // 	icon: 'shopping_cart',
    // 	component: <Shop username={username}/>
    // },
    // Help: {
    //   icon: "help_center",
    //   component: <Help username={username} email={email} />,
    // },
    Account: {
      icon: "account_circle",
      component: <Account username={username} />,
    },
    "Beta Testers": {
      icon: "supervised_user_circle",
      component: <BetaTesters />,
    },
    Admin: {
      icon: "query_stats",
      component: <Admin />,
    },
    // Interactions: {
    // icon: "warning",
    // component: <Interactions username={username} />,
    //},
  };

  const re = {
    pathname: "/",
    state: { logout: logout },
  };

  let propAccountMessage = accountMessage;
  return (
    <div class={styles.home}>
      {!isMobile && (
        <div className={styles.navbarDesktop}>
          {Object.keys(types).map((type, index) => {
            if (
              type !== "Admin" ||
              user_status === "admin" ||
              user_status === "developer"
            ) {
              return (
                <div
                  key={type}
                  className={
                    selected === type ? styles.navSelected : styles.navItem
                  }
                  onClick={() => setSelected(type)}
                >
                  <i className="material-icons md-light">{types[type].icon}</i>
                  <div className={styles.navText}>{type}</div>
                </div>
              );
            }
          })}

          <div className={styles.logout} onClick={() => logoutAcc()}>
            Log Out
          </div>
        </div>
      )}

      {isMobile && (
        <div className={styles.navbarMobile}>
          <div className={styles.hamburgerMenu} onClick={toggleMenu}>
            <i className="material-icons md-light">menu</i>{" "}
            {/* Hamburger icon */}
          </div>

          {menuOpen && (
            <div className={styles.mobileMenu}>
              <ul>
                <li onClick={() => setSelected("Home")}>Home</li>
                <li onClick={() => setSelected("Inventory")}>Inventory</li>
                <li onClick={() => setSelected("Visualize")}>Visualize</li>
                <li onClick={() => setSelected("Account")}>Account</li>
                <li onClick={() => setSelected("Beta Testers")}>
                  Beta Testers
                </li>
                <li onClick={() => logoutAcc()}>Logout</li>
              </ul>
            </div>
          )}
        </div>
      )}
      {!isMobile && (
        <div class={styles.content}>
          {types[selected].component}
          <div class={styles.footer}>
            <a href="/disclaimer">Terms Of Service |</a>
            <a href="/privacy">Privacy Policy |</a>
            <a href="/disclaim">Disclaimer |</a>
            <a href="/missionstatement"> Mission Statement |</a>
            <a href="/vision"> Vision | </a>
            <a href="/contact"> Contact us |</a>
            <a href="https://youtu.be/uZtd-_0LtjI?si=4A1vO4cIen46IJzy">
              {" "}
              User Tutorial Video{" "}
            </a>
          </div>
          <div class={styles.citation}>
            Products sourced from the National Institutes of Health, Office of
            Dietary Supplements. Dietary Supplement Label Database, 2022.
            https://dsld.od.nih.gov/.
          </div>
          <div class={styles.TM}> NutraData™ </div>
        </div>
      )}

      {isMobile && (
        <div class={styles.contentMobile}>
          {types[selected].component}
          <div class={styles.footer}>
            <a href="/disclaimer">Terms Of Service |</a>
            <a href="/privacy">Privacy Policy |</a>
            <a href="/disclaim">Disclaimer |</a>
            <a href="/missionstatement"> Mission Statement |</a>
            <a href="/vision"> Vision | </a>
            <a href="/contact"> Contact us |</a>
            <a href="https://youtu.be/uZtd-_0LtjI?si=4A1vO4cIen46IJzy">
              {" "}
              User Tutorial Video{" "}
            </a>
          </div>
          <div class={styles.citation}>
            Products sourced from the National Institutes of Health, Office of
            Dietary Supplements. Dietary Supplement Label Database, 2022.
            https://dsld.od.nih.gov/.
          </div>
          <div class={styles.TM}> NutraData™ </div>
        </div>
      )}
      {logout ? <Redirect to={re} /> : null}
      {accountMessage && accountMessage !== "User Acknowledged" ? (
        <Message
          messages={propAccountMessage}
          username={username}
          parentCallback={handleButtonClick}
        />
      ) : null}
    </div>
  );
}
